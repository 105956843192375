import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { Button } from 'reactstrap';

import {
  Footer,
  Loading,
  DetectSession,
  Header,
  NotFound,
  Text,
} from './../../components';
import { updateCookie, detectActiveSession } from './../../utils/helper';
import { disableWallets } from './../../config';

import PaymentForm from './PaymentForm';
import { PAYMENT_STATUS } from './../../constants';
import { PRIMARY_COLOR } from 'theme';
import api from 'utils/api';

const Payment = ({ organization, user, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const location = useLocation();
  const history = useHistory();
  const [cookies, setCookies] = useCookies();
  const [stripePromise, setStripePromise] = useState(() =>
    loadStripe(
      organization.configurations?.test_mode
        ? process.env.REACT_APP_DEVELOPMENT_STRIPE_KEY
        : process.env.REACT_APP_PRODUCTION_STRIPE_KEY,
      {
        apiVersion: '2020-08-27',
        stripeAccount: organization.stripe_connected_account_id,
      }
    )
  );
  const [isActiveSession, setIsActiveSession] = useState(false);
  const [isActiveSessionButtonText, setIsActiveSessionButtonText] = useState(
    'Not allowed due to existing pre-auth'
  );
  const { payment_id } = useParams();
  const [session, setSession] = useState({});
  const [sessionData, setSessionData] = useState({});
  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;

  useEffect(() => {
    init();
    getSessionData();
  }, []);

  const init = async () => {
    const status = await detectActiveSession({
      sessionId: cookies.store.sessionId,
      paymentId: payment_id,
    });

    setSession(status);

    if (Object.keys(status).length > 0) {
      setIsActiveSessionButtonText(`❌ Existing ${status.type} ❌`);
      setIsActiveSession(true);
    } else {
      setIsActiveSession(false);
    }
  };

  const getSessionData = async () => {
    const response_session = await api.get(
      null,
      `v1/public/sessions/${
        cookies?.store?.sessionId ?? location?.state?.sessionId
      }`
    );
    setSessionData(response_session.result);
  };

  if (!stripePromise) {
    return (
      <>
        <Loading />
      </>
    );
  } else if (!disableWallets() && isAuthenticated) {
    return (
      <>
        <NotFound />
      </>
    );
  } else if (
    session &&
    session.payment &&
    session?.payment?.status !== PAYMENT_STATUS.REQUIRES_PAYMENT_METHOD
  ) {
    return (
      <>
        <Header organization={organization} user={user} auth0User={auth0User} />
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '85vh',
            backgroundColor: '#F6F6F6',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px 40px',
          }}
        >
          <Text
            id="cancel-charging-text"
            size={20}
            style={{ textAlign: 'center' }}
          >
            Payment Session Expired.
          </Text>
          <br />
          <br />
          <Button
            id="go-back"
            className="action-button"
            style={{
              color: 'white',
              backgroundColor: organizationColor,
              borderColor: organizationColor,
            }}
            onClick={() => {
              history.goBack();
            }}
          >
            <Text color="white" size={18} weight={600}>
              Go Back
            </Text>
          </Button>
        </div>
      </>
    );
  } else {
    return (
      <div style={{ backgroundColor: 'white' }}>
        {isActiveSession ? (
          <DetectSession
            cookies={cookies}
            setCookies={setCookies}
            updateCookie={updateCookie}
          />
        ) : null}

        <Header organization={organization} user={user} auth0User={auth0User} />
        <Elements stripe={stripePromise}>
          <PaymentForm
            organization={organization}
            user={user}
            location={location}
            isActiveSession={isActiveSession}
            isActiveSessionButtonText={isActiveSessionButtonText}
            auth0User={auth0User}
            sessionData={sessionData}
          />
        </Elements>
        <Footer organization={organization} showSupportChat={true} />
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    organization: state.organization,
    status: state.status,
    user: state.user,
    auth0User: state.auth0User,
  };
};

export default connect(mapStateToProps)(Payment);
