import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory, useParams } from 'react-router';
import { useCookies } from 'react-cookie';
import { IoMailOutline, IoCallOutline, IoLocationSharp } from 'react-icons/io5';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment-timezone';

import ConnectorAvailable from './../../../assets/img/connector_available.png';
import ConnectorPluggedIn from './../../../assets/img/connector_plugged_in.png';

import api from 'utils/api';
import {
  Footer,
  Header,
  Loading,
  SigninButton,
  Text,
  SigninPage,
} from 'components';
import { disableAccounts } from 'config';
import {
  convertCurrencyFromText,
  hhmmss,
  updateCookie,
  ToastZoomTransition,
} from 'utils/helper';

import Information from './../../Chargestation/Information';
import SupportInformation from './SupportInformation';
import ChargingBatteryIcon from './ChargingBatteryIcon';
import DetailCard from './DetailCard';
import StartButton from './StartButton';
import StopButton from './StopButton';
import CancelButton from './CancelButton';
import './Session.css';
import {
  SESSION_STATUS,
  SESSION_TYPE,
  CONNECTOR_STATUS,
  TRANSACTION_STATUS,
} from './../../../constants';
import {
  RENDER_DATA_CONNECTION_LOST,
  RENDER_DATA_DEFAULT,
  RENDER_DATA_PUBLIC_START_FAIL,
  RENDER_DATA_PUBLIC_STOP_FAIL,
  RENDER_DATA_PAYMENT_INTENT_FAIL,
  createRenderDataObject,
  RENDER_DATA_PUBLIC_PAUSE_FAIL,
} from './RenderDataObjects';
import { PRIMARY_COLOR } from 'theme';
import { UnauthorizedPage } from 'pages';
import PauseButton from './PauseButton';
import ResumeButton from './ResumeButton';

const setRenderDataFromConnectorStatus = (
  currentState,
  previousState,
  renderData,
  setRenderData,
  transaction,
  organization,
  stopCommnadSuccess,
  session
) => {
  if (
    transaction &&
    transaction.status &&
    transaction.status === TRANSACTION_STATUS.ENDED
  ) {
    setRenderData(
      createRenderDataObject(
        'Finished Charging',
        `Thank you for charging with ${organization?.name}. A detailed receipt has been emailed to you. (Please check your spam folders)`,
        '',
        convertCurrencyFromText(transaction?.cost?.currency) +
          ' ' +
          parseFloat(transaction?.cost?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (session.paused === true) {
    setRenderData(
      createRenderDataObject(
        'Energy Flow Paused',
        'Please disconnect cable to stop charging when done',
        '',
        (transaction?.cost_estimate?.currency
          ? convertCurrencyFromText(transaction?.cost_estimate?.currency)
          : '') +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`,
        true
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.AVAILABLE &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Start Charging',
        'Please plug-in cable to your car to start charging.',
        ConnectorAvailable,
        '',
        true,
        false,
        '',
        ''
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.PREPARING &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Start Charging',
        'Thanks for plugging in. Please click the Start Charging button.',
        ConnectorPluggedIn,
        '',
        true,
        false,
        '',
        ''
      )
    );
  } else if (
    currentState === CONNECTOR_STATUS.SUSPENDEDEV &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'EV Charged',
        'Please disconnect cable or click Stop Charging below',
        '',
        (transaction?.cost_estimate?.currency
          ? convertCurrencyFromText(transaction?.cost_estimate?.currency)
          : '') +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        true,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
    //Add ,more condition
  } else if (
    currentState === CONNECTOR_STATUS.CHARGING &&
    !stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Charging',
        '',
        '',
        (transaction?.cost_estimate?.currency
          ? convertCurrencyFromText(transaction?.cost_estimate?.currency)
          : '') +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        true,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    (previousState === CONNECTOR_STATUS.CHARGING &&
      (currentState === CONNECTOR_STATUS.AVAILABLE ||
        currentState === CONNECTOR_STATUS.FINISHING ||
        currentState === CONNECTOR_STATUS.PREPARING)) ||
    stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Session Finishing',
        'Awaiting final transaction readings from the charge station',
        '',
        convertCurrencyFromText(transaction?.cost_estimate?.currency) +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  } else if (
    (previousState === CONNECTOR_STATUS.SUSPENDEDEV &&
      (currentState === CONNECTOR_STATUS.AVAILABLE ||
        currentState === CONNECTOR_STATUS.UNAVAILABLE)) ||
    stopCommnadSuccess
  ) {
    setRenderData(
      createRenderDataObject(
        'Session Finishing',
        'Awaiting final transaction readings from the charge station',
        '',
        convertCurrencyFromText(transaction?.cost_estimate?.currency) +
          ' ' +
          parseFloat(transaction?.cost_estimate?.amount ?? 0).toFixed(2),
        false,
        false,
        `${hhmmss(transaction?.metrics?.timeSpentCharging ?? 0)}`,
        `${parseFloat(
          (transaction?.metrics?.wattHoursConsumed ?? 0) / 1000
        ).toFixed(2)}kWh`
      )
    );
  }
};

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    let id = setInterval(() => {
      savedCallback.current();
    }, delay);
    return () => clearInterval(id);
  }, [delay]);
}

const Session = ({ organization, user, auth0User }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const history = useHistory();
  const [cookies, setCookies] = useCookies();
  const [session, setSession] = useState();
  const [loading, setLoading] = useState(false);
  const [startCommandLoading, setStartCommandLoading] = useState(false);
  const [stopCommandLoading, setStopCommandLoading] = useState(false);
  const [pauseCommandLoading, setPauseCommandLoading] = useState(false);
  const [resumeCommandLoading, setResumeCommandLoading] = useState(false);
  const [stopCommandSuccess, setStopCommandSuccess] = useState(false);
  const [pauseCommandSuccess, setPauseCommandSuccess] = useState(false);
  const [resumeCommandSuccess, setResumeCommandSuccess] = useState(false);
  const [cancelCommandLoading, setCancelCommandLoading] = useState(false);
  const [connectorStatus, setConnectorStatus] = useState(
    CONNECTOR_STATUS.AVAILABLE
  );
  const [previousConnectorStatus, setPreviousConnectorStatus] = useState(
    CONNECTOR_STATUS.AVAILABLE
  );

  const [renderData, setRenderData] = useState(RENDER_DATA_DEFAULT);
  const { session_id } = useParams();
  const [validUser, setValidUser] = useState(true);

  const organizationColor =
    organization?.theme?.colors?.primary ?? PRIMARY_COLOR;

  useEffect(() => {
    fetchSession();
  }, []);

  useInterval(() => {
    fetchSession();
  }, 10000);

  useEffect(() => {
    if (session && session.connector && session.connector.status) {
      // Ignore the Unavailable status for the previous connector status
      if (connectorStatus !== CONNECTOR_STATUS.UNAVAILABLE) {
        setPreviousConnectorStatus(connectorStatus);
      }

      setConnectorStatus(session.connector.status);
      setRenderDataFromConnectorStatus(
        session.connector.status,
        previousConnectorStatus,
        renderData,
        setRenderData,
        session.transaction,
        organization,
        stopCommandSuccess,
        session
      );
    }
  }, [session, connectorStatus, previousConnectorStatus]);

  //TODO take out the receipt page - session ended status (which will have connector status finishing and available)
  // useEffect(() => {
  //   if (session?.status === SESSION_STATUS.ENDED) {
  //     // history.push(`/private/session/${session_id}/receipt`);
  //   }
  // }, [session]);

  const fetchSession = async () => {
    let token;
    let url;

    if (isAuthenticated) {
      token = await getAccessTokenSilently();
      url = `v1/sessions/${session_id}`;
    } else {
      url = `v1/public/sessions/${session_id}`;
    }

    try {
      const response_session = await api.get(token, url);
      if (response_session.ok) {
        setSession(response_session.result);
        updateCookie(
          {
            sessionId: response_session.result?._id,
          },
          cookies,
          setCookies
        );
      } else {
        setValidUser(false);
      }
    } catch (e) {
      setRenderData(RENDER_DATA_CONNECTION_LOST);
    }
  };

  const onClickStartCharging = async (event) => {
    event.preventDefault();
    setStartCommandLoading(true);

    let token;
    let url;

    try {
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        url = `v1/sessions/${session_id}/start`;
      } else {
        url = `v1/public/sessions/${session_id}/start`;
      }

      const response_session_start = await api.get(token, url);

      if (!response_session_start.ok) {
        toast.info(response_session_start.message);
      }
      setTimeout(fetchSession, 2000);
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_START_FAIL);
      setStartCommandLoading(false);
    }
  };

  const onClickStopCharging = async (event) => {
    event.preventDefault();
    setStopCommandLoading(true);

    let token;
    let url;

    try {
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        url = `v1/sessions/${session_id}/stop`;
      } else {
        url = `v1/public/sessions/${session_id}/stop`;
      }

      const response_session_stop = await api.get(token, url);

      if (!response_session_stop.ok) {
        toast.info(response_session_stop.message);
      }

      setStopCommandSuccess(true);
      setTimeout(fetchSession, 2000);
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_STOP_FAIL);
      setStopCommandLoading(false);
    }
  };

  const onClickPauseCharging = async (event) => {
    event.preventDefault();
    setPauseCommandLoading(true);

    let token;
    let url;

    try {
      url = `v1/public/sessions/${session_id}/pause`;

      const response_session_pause = await api.get(token, url);

      if (!response_session_pause.ok) {
        toast.info(response_session_pause.message);
      }

      setPauseCommandSuccess(true);
      setPauseCommandLoading(false);
      setTimeout(fetchSession, 2000);
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_PAUSE_FAIL);
      setPauseCommandLoading(false);
    }
  };

  const onClickResumeCharging = async (event) => {
    event.preventDefault();
    setResumeCommandLoading(true);

    let token;
    let url;

    try {
      url = `v1/public/sessions/${session_id}/resume`;

      const response_session_resume = await api.get(token, url);

      if (!response_session_resume.ok) {
        toast.info(response_session_resume.message);
      }

      setResumeCommandSuccess(true);
      setResumeCommandLoading(false);
      setTimeout(fetchSession, 2000);
    } catch (e) {
      setRenderData(RENDER_DATA_PUBLIC_PAUSE_FAIL);
      setResumeCommandLoading(false);
    }
  };

  const onClickCancel = async (event) => {
    event.preventDefault();
    setCancelCommandLoading(true);

    let token;
    let url;

    try {
      if (isAuthenticated) {
        token = await getAccessTokenSilently();
        url = `v1/sessions/${session_id}/cancel`;
      } else {
        url = `v1/public/sessions/${session_id}/cancel`;
      }

      const response_session_cancel = await api.get(token, url);

      if (!response_session_cancel.ok) {
        toast.info(response_session_cancel.message);
      }
    } catch (e) {
      setRenderData(RENDER_DATA_PAYMENT_INTENT_FAIL);
    }
  };

  if (loading) {
    return <Loading />;
  }

  if (!validUser) {
    return <UnauthorizedPage title="Session" />;
  }

  if (!session) {
    return <Loading />;
  }

  if (session.status === SESSION_STATUS.CANCELED) {
    return (
      <>
        <Header organization={organization} user={user} auth0User={auth0User} />
        <div
          style={{
            display: 'flex',
            flex: 1,
            height: '100vh',
            backgroundColor: '#F6F6F6',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '12px 40px',
          }}
        >
          <Text
            id="cancel-charging-text"
            size={20}
            style={{ textAlign: 'center' }}
          >
            Charging session terminated.
          </Text>
          {/* <Text
            weight={400}
            size={14}
            color="#98A1A9"
            style={{ textAlign: 'center' }}
          >
            Pre-auth payment has been released and a confirmation has been sent
            to you via email.
          </Text> */}
          <br />
          <br />
          <Button
            id="go-back"
            className="action-button"
            style={{
              color: 'white',
              backgroundColor: organizationColor,
              borderColor: organizationColor,
              // opacity: startCommandLoading || stopCommandLoading ? 0.4 : 1,
            }}
            onClick={() => {
              history.replace(`/${session?.chargestation?.static_endpoint}`);
            }}
          >
            <Text color="white" size={18} weight={600}>
              Go Back
            </Text>
          </Button>
        </div>
        <Footer organization={organization} showSupportChat={true} />
      </>
    );
  } else {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div className="session-body">
          <Header
            organization={organization}
            user={user}
            auth0User={auth0User}
          />
          <ToastContainer
            position="bottom-center"
            hideProgressBar
            autoClose={2500}
            transition={ToastZoomTransition()}
            closeButton={false}
          />
          <div
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                minHeight: 100,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0 40px',
              }}
            >
              <Text
                weight={600}
                size={20}
                id="title"
                style={{ textAlign: 'center' }}
              >
                {renderData.title}
              </Text>
              <Text weight={600} size={30}>
                <ChargingBatteryIcon show={renderData.title === 'Charging'} />
              </Text>
              <br />
              <Text
                weight={400}
                size={14}
                color="#98A1A9"
                style={{ textAlign: 'center' }}
                id="sub-title"
              >
                {renderData.subTitle}
              </Text>
            </div>
            <div
              style={{
                minHeight: 300,
                display: 'flex',
                justifyContent: renderData.image ? 'center' : 'space-between',
                alignItems: 'center',
                flexDirection: 'column',
              }}
            >
              {renderData.image ? (
                <img src={renderData.image} />
              ) : (
                <Text weight={600} size={56}>
                  {renderData.imageAlt}
                </Text>
              )}

              <div style={{ width: '100%' }}>
                <DetailCard
                  id="charging-time-container"
                  title="Charging time"
                  detail={renderData.chargingTime}
                  show={!renderData.image}
                  organizationColor={organizationColor}
                />
                <DetailCard
                  id="energy-delivered-container"
                  title="Energy Delivered"
                  detail={renderData.energyDelivered}
                  show={!renderData.image}
                  organizationColor={organizationColor}
                />
              </div>
            </div>
          </div>
          {!(
            renderData.title === 'Charging' ||
            renderData.title === 'Finished Charging' ||
            renderData.title === 'Session Finishing'
          ) ? (
            <div
              style={{
                margin: 10,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Text size={16} weight="600">
                {session?.chargestation?.static_endpoint
                  ? `${session?.chargestation?.static_endpoint} - ${session?.chargestation?.location?.address?.streetAndNumber}`
                  : ''}
              </Text>
              <Text>
                {session?.createdAt
                  ? `Session will auto expire at ${moment(session.createdAt)
                      .add(5, 'minutes')
                      .format('hh:mm A')} ${moment
                      .tz(moment.tz.guess())
                      .zoneAbbr()}`
                  : ''}
              </Text>
            </div>
          ) : null}
          <StartButton
            show={renderData.startCharginButton}
            startCommandLoading={startCommandLoading}
            cancelCommandLoading={cancelCommandLoading}
            token={session.token}
            validConnectorStatus={
              session.connector.status === CONNECTOR_STATUS.PREPARING
            }
            organizationColor={organizationColor}
            onClick={onClickStartCharging}
          />
          <PauseButton
            show={renderData.stopCharginButton}
            pauseCommandLoading={pauseCommandLoading}
            token={session.token}
            organizationColor={organizationColor}
            onClick={onClickPauseCharging}
          />
          <ResumeButton
            show={renderData.resumeChargingButton}
            pauseCommandLoading={resumeCommandLoading}
            token={session.token}
            organizationColor={organizationColor}
            onClick={onClickResumeCharging}
          />
          {/* <StopButton
            show={renderData.stopCharginButton}
            stopCommandLoading={stopCommandLoading}
            token={session.token}
            organizationColor={organizationColor}
            onClick={onClickStopCharging}
          /> */}
          <CancelButton
            show={renderData.startCharginButton && !session.token}
            startCommandLoading={startCommandLoading}
            cancelCommandLoading={cancelCommandLoading}
            onClick={onClickCancel}
          />
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            backgroundColor: 'white',
            padding: 8,
            paddingTop: 20,
          }}
        >
          <Information
            Icon={IoMailOutline}
            type="Support Email"
            value={organization?.support?.email}
            href={`mailto:${organization?.support?.email}`}
            color={organizationColor}
            show={organization?.support?.email}
          />
          <Information
            Icon={IoCallOutline}
            type="Support Phone"
            value={organization?.support?.contact_number}
            href={`tel:${organization?.support?.contact_number}`}
            color={organizationColor}
            show={organization?.support?.contact_number}
          />
          <Information
            Icon={IoLocationSharp}
            type="Location"
            value={`${
              session?.chargestation?.location?.address?.streetAndNumber ?? ''
            } ${session?.chargestation?.location?.address?.city ?? ''}
              ${session?.chargestation?.location?.address?.postalCode ?? ''}
              ${session?.chargestation?.location?.address?.state ?? ''}`}
            show={session?.chargestation?.location?.address}
          />
        </div>
        <SupportInformation
          show={
            !(
              renderData.title === 'Charging' ||
              renderData.title === 'Finished Charging' ||
              renderData.title === 'Session Finishing'
            )
          }
        />
        <SigninButton organization={organization} show={!disableAccounts()} />
        <Footer organization={organization} showSupportChat={true} />
      </div>
    );
  }
};

const mapStateToProps = (state) => ({
  organization: state.organization,
  user: state.user,
  auth0User: state.auth0User,
});

export default connect(mapStateToProps)(Session);
